<template>
  <!-- 场地管理 -->
  <div class="AreaManger">
    <div class="top_search">
      <div class="top_search">
        <div style="display: flex; align-items: center">
          <span style="width: 108px">场地名称：</span>
          <el-input v-model="keyword" placeholder="请输入内容" :disabled="!issearch"></el-input>
        </div>
        <div style="margin-left: 30px">
          <span>场地类型：</span>
          <el-select v-model="address_type_id" placeholder="请选择" :disabled="!issearch">
            <el-option v-for="item in options" :key="item.id" :label="item.address_type_name" :value="item.id">
            </el-option>
          </el-select>
        </div>
        <el-button type="primary" size="small" style="margin-left: 30px" @click="searchinfo()" v-if="issearch"
          >搜索
        </el-button>
        <el-button type="warning" style="margin-left: 30px" @click="tuisearch()" v-else>取消搜索</el-button>
      </div>
      <div style="display: flex; justify-content: flex-end">
        <el-button
          size="small"
          type="primary"
          @click="
            dialogFormVisible = true;
            type = 'add';
            form = { wap: `城e充` };
          "
          >添加场地</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="
            dialogFormVisible1 = true;
            type1 = 'add';
          "
          >添加场地类型</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="
            dialogFormVisible2 = true;
            type1 = 'revise';
          "
          >编辑场地类型</el-button
        >
      </div>
    </div>
    <el-table
      :data="tableData"
      border
      style="
        width: 100%;
        margin-top: 30px;
        max-height: 70vh;
        overflow-y: scroll;
      "
    >
      <el-table-column prop="address_name" label="场地名称"></el-table-column>
      <el-table-column prop="addressL" label="场地地区"></el-table-column>
      <el-table-column prop="address" label="详细地址"></el-table-column>

      <el-table-column prop="address_type_name" label="场地类型" width="80px"></el-table-column>
      <el-table-column prop="created_at" label="创建时间"></el-table-column>
      <el-table-column label="紧急联系人" prop="contact_person"></el-table-column>
      <el-table-column prop="address_packages" label="套餐情况" width="300px">
        <template #default="scope">
          <span v-for="item in scope.row.address_packages" :key="item">{{ item.package_name }},</span>
        </template>
      </el-table-column>
      <el-table-column prop="is_use" label="使用情况" width="100px">
        <template #default="scope">
          {{ scope.row.is_use == 1 ? "使用中" : "未使用" }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250px">
        <template #default="scope">
          <el-button
            size="small"
            @click="$router.push('/accessControlList?id=' + scope.row.id)"
            type="primary"
            style="margin-bottom: 10px"
            >门禁管理</el-button
          >
          <el-button size="small" type="primary" @click="$router.push('/areaAdmin?address_id=' + scope.row.id)"
            >人员管理
          </el-button>
          <el-button type="danger" size="small" @click.prevent="deleteRow(scope.$index, tableData)">删除</el-button>
          <el-button type="warning" size="small" @click="handleClick(scope.row, scope.$index)">编辑</el-button>
          <el-button type="primary" size="small" @click="$router.push('/cartManger?id=' + scope.row.id)"
            >卡管理</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :title="type == 'add' ? '添加场地' : '编辑场地'" v-model="dialogFormVisible" width="600px">
      <el-form :model="form">
        <el-form-item label="场地名称" :label-width="formLabelWidth">
          <el-input v-model="form.address_name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="场地地区" :label-width="formLabelWidth">
          <elui-china-area-dht @change="onChange"> </elui-china-area-dht>
        </el-form-item>
        <el-form-item label="详细地址" :label-width="formLabelWidth">
          <el-input v-model="form.address" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="紧急联系电话" :label-width="formLabelWidth">
          <el-input v-model="form.contact_person"></el-input>
        </el-form-item>
        <el-form-item label="场地类型" :label-width="formLabelWidth">
          <el-select v-model="form.address_type_id" placeholder="请选择场地类型">
            <el-option v-for="(item, index) in options" :key="index" :label="item.address_type_name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="使用情况" :label-width="formLabelWidth">
          <el-select v-model="form.is_use" placeholder="场地状态">
            <el-option :label="item.name" :value="item.id" v-for="item in isuselist" :key="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="收款商户" :label-width="formLabelWidth">
          <el-select :disabled="true" v-model="form.wap" placeholder="请选择收款商户">
            <el-option :label="item.name" :value="item.name" v-for="item in shanghu" :key="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="充电模式" :label-width="formLabelWidth">
          <el-select v-model="form.shou" placeholder="请选择充电模式">
            <el-option label="套餐收费" :value="1"></el-option>
            <el-option label="按量收费" :value="2"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button
            @click="
              dialogFormVisible = false;
              form = {};
            "
            >取 消</el-button
          >
          <el-button type="primary" @click="addcurse()">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog title="添加场地类型" v-model="dialogFormVisible1" width="400px">
      <el-form>
        <el-form-item label="场地类型名称">
          <el-input v-model="address_type_name" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible1 = false">取 消</el-button>
          <el-button type="primary" @click="addtype()">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog title="编辑场地类型" v-model="dialogFormVisible2" width="30%">
      <el-table :data="options" border style="width: 90%; margin: 0 auto; margin-top: 30px">
        <el-table-column prop="address_type_name" label="场地类型"></el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-button type="danger" size="small" @click.prevent="deleteRow(scope.$index, options)">删除</el-button>
            <el-button type="primary" size="small" @click="handleClick2(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible2 = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import {
  addressList,
  addressAdd,
  addressEdit,
  addressDel,
  addressType,
  addressTypeEdit,
  addressTypeDel,
} from "../../request/api";
// 参数取值
import { EluiChinaAreaDht } from "elui-china-area-dht";
import { ElMessage } from "element-plus";
export default {
  components: {
    EluiChinaAreaDht,
  },
  props: ["page", "pagepage"],
  data() {
    return {
      address_packages: [1, 2, 3],
      isuselist: [
        {
          name: "使用中",
          id: 1,
        },
        {
          name: "未使用",
          id: 0,
        },
      ],
      keyword: "", //top的场地名称
      address_type_name: "", //添加场地名称弹框input
      address_type_id: "", //top的场地类型
      dialogFormVisible: false, //添加场地弹窗
      dialogFormVisible1: false, //添加场地类型弹窗
      dialogFormVisible2: false, //编辑场地类型弹窗
      form: {
        address_name: "",
        province: "",
        city: "",
        district: "",
        address: "",
        is_default: "1",
        address_type_id: "",
        is_use: "",
        province_id: "",
        city_id: "",
        district_id: "",
        contact_person: "",
        wap: "城e充",
      },
      name: "120000",
      formLabelWidth: "120px",
      tableData: [],
      tableDataCopy: [], //搜索功能
      options: [],
      type: "add", //判断是增加还是修改
      type1: "add", //判断是添加场地类型还是修改场地类型
      address_type_id: "", //修改场地类型的id
      issearch: true, //显示搜索还是取消搜索
      province_id: "",
      city_id: "",
      district_id: "",
      index: "", //修改当前项
      pageTotal: 0,

      shanghu: [
        {
          name: "城e充",
          key: 2,
        },
      ], // 收款商户
    };
  },
  async created() {
    this.alList();
  },
  watch: {
    contact_person(old) {
      old = old.replace('"', "");
      old = old.split(",");
      this.form.contact_person = old;
    },
    page() {
      if (this.issearch) {
        this.alList();
      } else {
        this.searchinfo();
      }
    },
    pagepage() {
      if (this.issearch) {
        this.alList();
      } else {
        this.searchinfo();
      }
    },
  },
  methods: {
    // 编辑场地类型 的编辑按钮
    handleClick2(item, index) {
      this.dialogFormVisible1 = true;
      this.address_type_name = item.address_type_name;
      this.address_type_id = item.id;
      this.type1 = "revise";
    },
    searchinfo() {
      addressList({
        page: this.page,
        page_size: this.pagepage,
        keyword: this.keyword, //场地名称
        address_type_id: this.address_type_id,
      }).then((res) => {
        this.$emit("pagetotalClick", res.total);
        if (res.code == 200) {
          this.tableData = res.list.address_list;
          this.tableData.forEach((item) => {
            item.addressL = item.province + item.city + item.district;
          });
          this.issearch = false;
        }
      });
    },
    tuisearch() {
      this.keyword = "";
      this.address_type_id = "";
      this.issearch = true;
      this.alList();
    },
    alList() {
      return new Promise((resolve, reject) => {
        addressList({ page: this.page, page_size: this.pagepage }).then((res) => {
          console.log("场地列表", res);
          resolve(res.total);
          this.$emit("pagetotalClick", res.total);
          this.pageTotal = res.total;
          this.options = res.list.address_type_list;
          this.tableData = res.list.address_list;
          this.tableData.forEach((item) => {
            item.addressL = item.province + item.city + item.district;
          });
          this.tableDataCopy = this.tableData;
        });
      });
    },
    //   删除
    deleteRow(index, rows) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (this.type1 == "revise") {
            console.log(rows, 789);
            addressTypeDel({ id: rows[index].id }).then();
            this.alList();
          } else {
            addressDel({ id: rows[index].id }).then((res) => {
              if (res.code == 200) {
                this.alList();
              }
            });
          }
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleClick(item, index) {
      this.index = index;
      this.dialogFormVisible = true;
      this.form.address_name = item.address_name;
      this.form.province = item.province;
      this.form.city = item.city;
      this.form.district = item.district;
      this.form.address = item.address;
      this.form.is_default = item.is_default;
      this.form.address_type_id = item.address_type_id;
      this.form.is_use = item.is_use;
      this.form.contact_person = item.contact_person;
      this.form.wap = item.wap;
      this.form.shou = item.shou;
      this.form.id = item.id;
      this.type = "revise";
    },
    addcurse() {
      this.form.is_default = "1";
      if (this.type == "add") {
        this.form.contact_person = this.form.contact_person.split(",");
        addressAdd(this.form).then((res) => {
          if (res.code == 200) {
            this.alList();
            ElMessage.success({
              message: res.msg,
              type: "success",
            });
            this.dialogFormVisible = false;
            this.form = {};
          } else {
            ElMessage.error(res.msg);
          }
        });
      } else if (this.type == "revise") {
        console.log(typeof this.form.contact_person);
        typeof this.form.contact_person != "object"
          ? (this.form.contact_person = this.form.contact_person.split(","))
          : "";
        this.form.is_use = this.form.is_use == "使用中" ? "1" : this.form.is_use == "未使用" ? "0" : this.form.is_use;
        addressEdit(this.form).then((res) => {
          if (res.code == 200) {
            this.alList();
            ElMessage.success({
              message: res.msg,
              type: "success",
            });
            this.dialogFormVisible = false;
          } else {
            ElMessage.error(res.msg);
          }
        });
      }
    },
    onChange(e) {
      const chinaData = new EluiChinaAreaDht.ChinaArea().chinaAreaflat;
      const one = chinaData[e[0]];
      const two = chinaData[e[1]];
      const three = chinaData[e[2]];
      this.form.province_id = chinaData[e[0]].value;
      this.form.city_id = chinaData[e[1]].value;
      this.form.district_id = chinaData[e[2]].value;
      this.form.province = chinaData[e[0]].label;
      this.form.city = chinaData[e[1]].label;
      this.form.district = chinaData[e[2]].label;
    },
    //添加场地类型与编辑
    addtype() {
      if (this.type1 == "revise") {
        addressEdit({
          id: this.address_type_id,
          address_type_name: this.address_type_name,
        }).then((res) => {
          if (res.code == 200) {
            this.alList();
            ElMessage.success({
              message: res.msg,
              type: "success",
            });
            this.dialogFormVisible1 = false;
          } else {
            ElMessage.error(res.msg);
          }
        });
      } else if ((this.type1 = "add")) {
        addressType({ address_type_name: this.address_type_name }).then((res) => {
          if (res.code == 200) {
            this.alList();
            ElMessage.success({
              message: res.msg,
              type: "success",
            });
            this.dialogFormVisible1 = false;
            this.address_type_name = "";
          } else {
            ElMessage.error(res.msg);
          }
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.top_search {
  display: flex;
  justify-content: space-between;
}

/deep/.cell {
  text-align: center;
}
</style>
